<template>
  <div>
    <div class="antd-page-content">
      <a-row :gutter="12" style="padding:20px 15px 0px">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-col :span="6">
            <a-form-item label="课程名称">
              <a-input v-model="queryParams.courseName" placeholder="请输入课程名称" />
            </a-form-item>
          </a-col>
           <a-col :span="6">
            <a-form-item label="课程类型">
              <a-select :filter-option="false" show-search :allowClear="true" placeholder="请输入课程类型"
                v-model="queryParams.courseTypeId" @popupScroll="handlePopupScroll">
                <a-select-option v-for="item in channelList" v-bind:key="item.id" :value="item.id">
                  {{item.courseTypeName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQueryForm">重置</a-button>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
      <div class="antd-toolbar">
      </div>
      <s-table size="default" ref="table" rowKey="id" :data="loadData" :columns="columns" :pageNum="pageNum">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:void(0)" class="table-operation-action" @click="detailFormEvt(record)">进入考试</a>
        </template>
      </s-table>
    </div>
  </div>
</template>

<script>
import { getCourseType, getCourseTrnExamList } from '@/httpsAPI/course/index'

const columns = [
  {
    title: '序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title: '课程名称',
    dataIndex: 'courseName'
  },
  {
    title: '封面图',
    dataIndex: 'courseImageUrl'
  },
  {
    title: '及格线',
    dataIndex: 'examPassScore'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  name: "learningRecords",
  data () {
    return {
      columns,
      queryParams:{},
      channelList:[],
      pageNum: 1,
      form: this.$form.createForm(this, { name: 'searchProject' }),
      loadData: parameter => {
        this.pageNum = parameter.pageNo
        return getCourseTrnExamList(Object.assign(parameter,this.queryParams)).then(res => {
          return res.data
        })
      },
    }
  },
  created(){
    this.changeChannelList ()
  },
  methods:{
     // 重置搜索form
    resetQueryForm () {
      this.queryParams = {}
      this.$refs.table.refresh(true)
    },
    changeChannelList () {
      getCourseType()
        .then(res => {
          this.channelList = res.data;
        })
    },
    // 课程类型下拉滚动
    handlePopupScroll (e) {
      const { target } = e
      const scrollHeight = target.scrollHeight - target.scrollTop
      const clientHeight = target.clientHeight
      // 下拉框不下拉的时候
      if (scrollHeight === 0 && clientHeight === 0) {
        this.scrollPage = 1
      } else {
        // 当下拉框滚动条到达底部的时候
        if (scrollHeight < clientHeight + 5) {
          this.pageData(1);
        } else if (scrollHeight == 3208) {
          this.pageData(2);
        }
      }
    },
    handleSearch (keyword) {
      this.keyword = keyword;
      this.changeChannelList();
    },
    detailFormEvt(record){
      this.$router.push({path:"/personalDevelopment/test/exam",query:{courseNo:record.courseNo}})
    }
  }
}
</script>

<style scoped>
</style>